.call-to-action .custom-btn {
    padding: 10px 20px;
    border: 1px solid #fff;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 3px;
}

.custom-btn-main {
    background-color: #50BC85 !important;
    border: none !important;
}

.call-to-action-principal-button {
    width: 35%;
}

@media only screen and (max-width : 900px) {
    .call-to-action-principal-button {
        width: 55%;
    }
}

.call-to-action .custom-btn svg {
    font-size: 27px;
    margin-right: 10px;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.call-to-action .custom-btn p {
    font-size: 12px;
    margin: 0;
    line-height: 1.4;
    color: #fff;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.call-to-action .custom-btn p span {
    display: block;
    font-size: 17px;
    color: #fff;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}