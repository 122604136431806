.card-item {
  height: 100%;
  border: 2px solid #f6f5f4;
  background-color: #f6f5f4;
  border-radius: 0.75rem;
}

.item-badge {
  top: 0;
  right: 16px;
  width: auto;
  padding: 4px 12px;
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0.75rem;
  position: absolute;
  font-size: 0.8rem;
}

.item-header {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 15px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.item-icon {
  font-size: 16px;
}

.bg-gray {
  background: #f6f5f4;
}

.container-p {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5em;
}

.item-pricing {
  padding-top: 15px;
  padding-bottom: 15px;
}

.base-btn {
  width: 100%;
  padding: 8px 35px;
  border: 2px solid #2a3572;
  text-align: center;
  display: inline-block;
  color: #2a3572;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.base-btn.btn-start:hover,
.inverse-btn-start {
  text-decoration: none;
  background-color: #2a3572;
  color: #fff;
  box-shadow: 0 5px 10px 0 rgba(111, 100, 231, 0.1);
}
.base-btn.inverse-btn-start:hover {
  text-decoration: none;
  color: #fff;
}

ul.features-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.features-list li {
  font-size: 14px;
  margin-bottom: 5px;
}

ul.features-list li::before {
  content: "✔";
  margin-right: 5px;
  font-size: 12px;
}

.features-title {
  font-weight: bold;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.cross-text {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.regular-price {
  position: absolute;
}

.promo-price {
  margin-top: 27px;
}

.promo-label {
  font-size: 11px;
  color: #50bc85;
}
